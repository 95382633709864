










































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IProduct, IStore, ITransactions, IUser, PayloadState } from '@/types/types';
//const transactionsModule = namespace('transactions');
const authModule = namespace('auth');
@Component({
  name: 'GetDetails',
  components: {
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetDetails extends mixins(WidgetMixins) {
  //@transactionsModule.Getter('getProductDetails') details!: IProduct;
  @authModule.Getter('getUserDetails') userDetails!: IUser & IStore;
  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;
  @Prop({ default: null }) details!: ITransactions;

  generatedLink: string = '';
  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'details',
      state: false,
    };
  }
}
